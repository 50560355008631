<template>
  <b-overlay opacity="0.17" blur="1rem" :show="showOverlay" rounded="md" variant="secondary">
    <b-modal id="modal-primary" ok-only ok-variant="primary" ok-title="Upload" title="Upload Third Parties"
      v-model="showBulkUploadModal" @ok="uploadCsv" size="lg" no-close-on-backdrop>
      <a href="third_party_bulk_upload.csv" class="text-sm float-right mb-2" download><feather-icon class="mr-50"
                icon="DownloadIcon" />Click here to download sample file</a>
      <b-form-file
      v-model="file"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
      accept=".csv,text/csv,text/plain"
    />

    </b-modal>
    <b-sidebar lazy id="sidebar-backdrop" sidebar-class="sidebar-lg" bg-variant="white" backdrop right no-header shadow>
      <add-vendor-user-form :closeSidebar="closeSidebar" :reloadParent="load" :vendorId="selectedVendorId" :key="selectedVendorId"/>
    </b-sidebar>
    <div class="card">
      <div class="card-header pb-75">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="d-flex flex-column align-items-start justify-content-start">
            <h3 class="mb-25 font-weight-bolder">Third Parties</h3>
            <p class="mb-0">Manage your third parties.</p>
          </div>
          <div class="d-flex  align-items-center justify-content-between">
            <b-button size="sm" @click="handleBulkUpload" variant="primary" class="mr-50"><feather-icon class="mr-50"
                icon="DownloadIcon" />Bulk Upload</b-button>
            <b-button size="sm" @click="handleNewVendorClick" variant="success"><feather-icon class="mr-50"
                icon="PlusIcon" />Add Third
              Party</b-button>
          </div>
        </div>
      </div>
      <div class="pb-25 pt-0 w-100 row">
        <div class="app-fixed-search col-md-8 col-sm-12">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon v-if="filters.search == null || filters.search == ''" icon="SearchIcon" class="text-muted" />
              <feather-icon @click="() => {
      filters.search = null;
    }
    " v-else icon="XIcon" class="text-danger cursor-pointer" />
            </b-input-group-prepend>
            <b-form-input v-model="filters.search" placeholder="Search..." debounce="500" size="md" />
          </b-input-group>
        </div>
      </div>

      <table role="table" class="table table-hover">
        <thead role="rowgroup">
          <tr role="row">
            <th role="columnheader" scope="col">#</th>
            <th role="columnheader" scope="col">Name</th>
            <!-- <th role="columnheader" scope="col" class="text-center">Total Users</th> -->
            <th role="columnheader" scope="col"  style="width: 200px;">Policies</th>
            <th role="columnheader" scope="col" class="tex-center">Risk</th>
            <th role="columnheader" scope="col" class="tex-center" style="width: 200px;">Compliance</th>
            <th role="columnheader" scope="col" class="tex-center" style="width: 200px;">Total Assessments</th>
            <th role="columnheader" scope="col" class="tex-center" style="width: 200px;">In progress Assessments</th>
            <th role="columnheader" scope="col" class="tex-center" style="width: 200px;">Completed Assessments</th>
            <th role="columnheader" scope="col" class="text-center" style="width: 200px;">
                  Actions
                </th>
            
            
          </tr>
        </thead>
        <tbody role="rowgroup">
          <tr
            v-for="(vendor, index) in vendorList"
            :key="vendor._id"
            role="row"
            class="cursor-pointer"
            @click="handleVendorClick(vendor._id)"
          >
            <td role="cell">
              {{ (filters.page - 1) * filters.limit + index + 1 }}
            </td>
            <td role="cell" class="text-start" style="max-width: 20vw">
              <p class="font-weight-bold mb-0">
                {{ vendor.name }}
              </p>
            </td>

            <!-- <td role="cell">
              <div class="w-100 d-flex align-items-center justify-content-center">
                <b-avatar variant="primary">
                  {{ vendor.users.length }}
                </b-avatar>
              </div>
            </td> -->

            <td role="cell">
              <div class="w-100 ">
                <!-- <b-avatar
                  variant="info"
                  v-if="vendor.policy_ids && vendor.policy_ids != null"
                >
                  {{ vendor.policy_ids.length }}
                </b-avatar> -->
                <b-badge v-for="policy in vendor.policies" class="mr-50 trim-badge" variant="info" :title="policy.title">{{ policy.title }}</b-badge>
              </div>
            </td>
            <td role="cell">
              <b-badge v-if="vendor.risk_value && vendor.risk_value != null" :variant="vendor.risk_value == 1?'success':vendor.risk_value == 2?'warning':vendor.risk_value == 3?'danger':''">{{ vendor.risk_value == 1?'Low':vendor.risk_value == 2?'Medium':vendor.risk_value == 3?'High':'Unknown' }}</b-badge>
            </td>
            <td role="cell">
              <!-- <ComplainceCard :complaince="vendor"/> -->
              <!-- <b-avatar variant="light-info">{{ vendor.compliance | round }}</b-avatar> -->
              <progress-badge :min="0" :max="100" title="Progress" :value="vendor.compliance | round"
                            :colorsReversed="true" />
            </td>  
            <td role="cell">
              <!-- <AssessmentCard :assessment_values="vendor"/> -->
              <b-avatar variant="light-info">{{ vendor.total_assessments }}</b-avatar>
            </td>
            <td role="cell">
              <b-avatar variant="light-warning">{{ vendor.in_progress_assessments }}</b-avatar>
            </td>
            <td role="cell">
              <b-avatar variant="light-success">{{ vendor.completed_assessments }}</b-avatar>
            </td>
           <td role="cell">
                  <div class="d-flex justify-content-center">
                    <b-button
                      @click.stop="handleEditClick(vendor._id)"
                      b-v-tooltip.hover.top.v-secondary
                      title="Edit Assessment"
                      variant="primary"
                      size="sm"
                      >
                      <span >
                    Edit</span></b-button>
                  </div>
                </td>
          </tr>
        </tbody>
      </table>

      <div class="card-body">
        <div class="d-flex align-items-center justify-content-center w-100">
          <b-pagination v-model="filters.page" :per-page="pagination.perPage"
            :total-rows="pagination.lastPage * pagination.perPage" aria-controls="my-table"></b-pagination>
          <!-- {{ pagination }} -->
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BOverlay,
  BSidebar,
  BPagination,
  BAvatar,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BBadge,
  BModal,
  BFormFile
} from "bootstrap-vue";
import AddVendorUserForm from "./components/AddVendorUserForm.vue";
import ResponseMixins from "../../mixins/ResponseMixins";
import ThirdPartyRisksMixins from "../../mixins/ThirdPartyRisksMixins";
import TaskMixins from "../../mixins/TaskMixins";
import ComplainceCard from "./components/ComplainceCard.vue";
import AssessmentCard from "./components/AssessmentCard.vue"
import VendorMixins from "../../mixins/VendorMixins"
import moment from "moment";
import ProgressBadge from "../../components/ProgressBadge.vue";

export default {
  components: {
    BButton,
    BSidebar,
    BOverlay,
    AddVendorUserForm,
    BPagination,
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BBadge,
    ComplainceCard,
    AssessmentCard,
    ProgressBadge,
    BModal,
    BFormFile
  },
  mixins: [ResponseMixins, ThirdPartyRisksMixins, TaskMixins,VendorMixins],
  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },
  data() {
    return {
      showOverlay: false,
      vendorList: null,
      pagination: {
        lastPage: 1,
        totalRows: 1,
        perPage: 10,
      },
      filters: {
        search: null,
        page: 1,
        limit: 10,
      },
      showBulkUploadModal: false,
      selectedVendorId:null,
      file:null
    };
  },
  watch: {
    filters: {
      handler: function (newValue) {
        this.getVendorsAndSetData(newValue);
      },
      deep: true,
    },
  },
  filters: {
    // Filter to round the value to 2 decimal places
    round(value) {
      if (Number.isInteger(value)) {
        return value;
      } else {
        return value.toFixed(2);
      }
    }
  },
  mounted() {
    if (this.isUserVendor()) {
      console.log("VendorId", this.$store.state.app.user.vendor_id);
      this.$router.push(
        `/thirdpartyrisks/${this.$store.state.app.user.vendor_id}/details`
      );
    } else {
      this.load();
    }
  },

  methods: {
   
    uploadCsv() {
      const maxSize = 5 * 1024 * 1024
      if(this.file.size>maxSize){
        this.showToast(`The uploaded file is too large, please upload a smaller file`,'danger')
        this.file=null
        return 
      }

      this.bulkUploadVendor(this.file).then((res)=>{
        this.handleResponse(res);
        this.showBulkUploadModal = false;
        this.getVendorsAndSetData();
      })
      .catch((err)=>{

        if(err.response&&err.response.status===422){
          const errors=err.response.data?.errors?.[0]?.errors||err.response?.data?.error
          // err.response.data.errors.forEach((error) => {
            Object.values(errors).forEach(item=>{
              this.showToast(`${item}`,'danger')
            })
          // });
          return
        }
        if(err.response?.status===500){
          this.showToast(`Something went wrong please check your file before upload`,'danger')
          return 
        }
        this.handleError(err)
      }).finally(()=>{
        this.file=null
      })
     },
    
    handleBulkUpload() {
      this.file=null
      this.showBulkUploadModal = true;
    },
    load() {
      this.getVendorsAndSetData(this.filters);
    },

    isUserVendor() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        return true;
      } else {
        return false;
      }
    },

    handleVendorClick(id) {
      this.$router.push(`/thirdpartyrisks/${id}/details`);
    },

    getVendorsAndSetData(params) {
      this.showOverlay = true;
      this.getVendors(params)
        .then((res) => {
          this.pagination.lastPage = res.data.data.last_page;
          this.pagination.totalRows = res.data.data.total;
          this.filters.page = res.data.data.current_page;
          this.filters.limit = res.data.data.per_page;
          this.vendorList = res.data.data.data;
          this.showOverlay = false;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleNewVendorClick() {
      this.openSidebar();
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      this.selectedVendorId=null;
    },
    openSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      });
    },
    handleEditClick:function(vendorId){
      this.selectedVendorId=vendorId
      this.openSidebar();
    }
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem;
}
</style>

<style lang="scss" scoped>
.table th {
  text-transform: none;
}

.filters_section {
  background-color: #f2f0f7;
}

.dark-layout {
  .filters_section {
    background-color: #161d31;
  }
}
</style>

<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
.trim-badge {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
