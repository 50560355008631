<template>
    <div>
        <div class="w-100 h-auto rounded mb-75 bg-light-primary border-primary px-1 py-25">
            Compliance :<b-badge class="ml-50" variant="info">{{ complaince_value.compliance | round }}</b-badge>
            <b-progress key="primary" animated :value="complaince_value.compliance" variant="primary"
                class="progress-bar-primary mt-50 mb-50" :max="100" striped />
        </div>
    </div>
</template>
<script>
import { BBadge, BProgress } from 'bootstrap-vue'
export default {
    data() {
        return {
            complaince_value: this.complaince
        }
    },
    components: {
        BBadge,
        BProgress
    },
    props: {
        complaince: {
            type: Object
        }
    },
    filters: {
    // Filter to round the value to 2 decimal places
    round(value) {
      if (Number.isInteger(value)) {
        return value;
      } else {
        return value.toFixed(2);
      }
    }
  }
}
</script>