<template>
    <div>
        <div class="w-100 h-auto rounded mb-75 bg-light-success border-success px-1 py-25"
           
             variant="outline-success"  :id="`popover-validity-${assessment_values._id}`">
            Total :<b-badge class="ml-50" variant="success">{{ assessment_values.total_assessments }}</b-badge>
            <b-progress key="success" animated :value="assessment_values.total_assessments" variant="success"
                class="progress-bar-success mt-50 mb-50" :max="100" striped />
            <b-popover :target="`popover-validity-${assessment_values._id}`" triggers="hover" placement="left">
                <template v-slot:title>
                    <span>Assessments</span>
                </template>
                <div class="d-flex flex-column align-items-start justify-content-start">
                    <div class="d-flex flex-column align-items-start justify-content-start">
                        <label class="font-weight-bolder">In Progress</label>
                        <p class="mb-0"><b-badge variant="success">{{ assessment_values.in_progress_assessments }}</b-badge></p>
                    </div>

                    <div class="d-flex flex-column align-items-start justify-content-start mt-1">
                        <label class="font-weight-bolder">Completed</label>
                        <p class="mb-0"><b-badge variant="success">{{ assessment_values.completed_assessments }}</b-badge></p>
                    </div>
                </div>
            </b-popover>
        </div>
    </div>
</template>
<script>
import { BBadge, BProgress, VBPopover, BPopover } from 'bootstrap-vue'
export default {
    data() {
        return {
            assessment_values: this.assessment_values
        }
    },
    components: {
        BBadge,
        BProgress,
        VBPopover,
        BPopover
    },
    directives: {
        'b-popover': VBPopover,

    },
    props: {
        assessment_values: {
            type: Object
        }
    }
}
</script>